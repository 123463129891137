<template>
  <v-container
    id="calendar"
    fluid
    class="no-gutters"
    tag="section"
  >
    <v-row class="fill-height">
      <v-col
        cols="12"
        class="mx-auto"
      >
        <v-card style="margin: 0 !important;">
          <v-sheet height="64">
            <v-toolbar
              flat
            >
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                {{ $t('app.general.today') }}
              </v-btn>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
              >
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
              >
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu
                bottom
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item :key="`key-${key}`" v-for="(label, key) in typeToLabel" @click="type = key">
                    <v-list-item-title>{{ label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet
            flat
            class="mt-5"
          >
            <v-calendar
              ref="calendar"
              v-model="calendar"
              :events="items"
              :type="type"
              :event-color="getBackgroundColorForEvent"
              :event-text-color="getTextColorForEvent"
              :now="$moment().format('YYYY-MM-DD hh:mm:ss')"
              :locale="$i18n.locale"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              category-show-all
              :categories="categories"
              event-overlap-mode="column"
              :first-interval="7"
              :interval-count="18"
            >
              <template v-slot:event="{event}">
                <div class="fill-height pl-2">

                  <strong>{{ event.name }}</strong>
                  <br>
                  {{ event.category }}
                  <br>
                  <em>
                    {{ event.type == 'tournament' ? $t('app.torneos.single') : $t('app.juegos_informales.single') }}
                  </em>
                </div>
              </template>
            </v-calendar>
            <v-dialog
              v-model="selectedOpen"
              :max-width="dialogWidth"
            >
              <v-card v-if="selectedEvent">
                <v-card-title class="pa-0">
                  <v-toolbar
                  >
                    <v-btn
                      icon
                      @click="selectedOpen = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      {{ selectedEvent.name }}
                    </v-toolbar-title>
                  </v-toolbar>
                </v-card-title>
                <v-card-text>
                  <template v-if="selectedEvent.type === 'tournament'">
                    <v-row>
                      <v-col
                        cols="5"
                        lg="4"
                      >
                        <base-material-card
                          color="white"
                          image
                        >
                          <template v-slot:image>
                            <v-img
                              :src="selectedEvent.details.equipo_local.logotipo || logo"
                              :lazy-src="preloader"
                            />
                          </template>
                          <v-card-title class="display-2 font-weight-medium">
                            {{ selectedEvent.details.equipo_local.nombre }}
                          </v-card-title>
                        </base-material-card>
                      </v-col>
                      <v-col
                        cols="2"
                        offset-lg="1"
                        align-self="center"
                      >
                        <v-img
                          :lazy-src="preloader"
                          :src="versusImg"
                          class="center-element"
                        ></v-img>
                      </v-col>
                      <v-col
                        cols="5"
                        lg="4"
                        offset-lg="1"
                      >
                        <base-material-card
                          color="white"
                          image
                        >
                          <template v-slot:image>
                            <v-img
                              :src="selectedEvent.details.equipo_visitante.logotipo || logo"
                              :lazy-src="preloader"
                            />
                          </template>
                          <v-card-title class="display-2 font-weight-medium">
                            {{ selectedEvent.details.equipo_visitante.nombre }}
                          </v-card-title>
                        </base-material-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-tournament
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ selectedEvent.details.torneo.nombre }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.torneos.single') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-clock
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $moment(selectedEvent.start).format('MMMM D, YYYY [@] HH:mm') }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.headers.start_date') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-clock
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $moment(selectedEvent.end).format('MMMM D, YYYY [@] HH:mm') }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.headers.end_date') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-office-building
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ selectedEvent.details.sucursal.nombre }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.sucursales.single') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-soccer-field
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ selectedEvent.details.cancha.nombre }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.canchas.single') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row>
                      <v-col>
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title class="display-1">
                                <strong>{{ $t('app.juegos_informales.single') }}</strong>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.headers.type') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-text-subject
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ selectedEvent.details.descripcion }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.headers.description') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-clock
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $moment(selectedEvent.start).format('MMMM D, YYYY [@] HH:mm') }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.headers.start_date') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-clock
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $moment(selectedEvent.end).format('MMMM D, YYYY [@] HH:mm') }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.headers.end_date') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-office-building
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ selectedEvent.details.sucursal.nombre }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.sucursales.single') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-soccer-field
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ selectedEvent.details.cancha.nombre }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.canchas.single') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-account-circle
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ selectedEvent.details.contacto }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.headers.contact') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-phone
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ selectedEvent.details.telefonos.join(', ') }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ $t('app.headers.phone') }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </template>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Components
  import { VBtn } from 'vuetify/lib'

  export default {
    name: 'DashboardCalendar',

    components: {
      CalendarBtn: {
        extends: VBtn,

        props: {
          color: {
            type: String,
            default: 'secondary',
          },
          minWidth: {
            type: Number,
            default: 0,
          },
          rounded: {
            type: Boolean,
            default: true,
          },
        },

        computed: {
          classes () {
            return {
              ...VBtn.options.computed.classes.call(this),
              'mx-1': true,
              'text-lowercase': true,
            }
          },
        },
      },
    },

    data: () => ({
      calendar: null,
      disabled: true,
      items: [],
      focus: '',
      type: 'week',
      types: [],
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
      },
      selectedEvent: null,
      selectedElement: null,
      selectedOpen: false,
      versusImg: require('../../assets/icons/versus.svg'),
      categories: [],
    }),
    async mounted () {
      this.toggleLoader()

      this.calendar = this.$moment().format('YYYY-MM-DD')
      this.typeToLabel = {
        month : this.$t('app.general.month'),
        week : this.$t('app.general.week'),
        day : this.$t('app.general.day'),
        category: 'Cancha'
      }
      await this.init()
      this.categories = await this.getCanchas()

      this.toggleLoader()
    },
    methods: {
      setToday () {
        this.calendar = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      viewDay ({ date }) {
        this.calendar = date
        this.type = 'day'
      },
      async init () {
        await this.$http.get(route('v1/calendar'))
          .then(response => {
            if (response.body.code === 200) {
              this.items = response.body.data
            } else {
              this.processError(response)
            }
          }, error => this.processError(error))
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      getBackgroundColorForEvent (item) {
        let color = false

        if (item.details.hasOwnProperty('torneo')) {
          color = this.rgbaColor(item.details.torneo.color)
        } else {
          color = this.rgbaColor(item.details.color)
        }

        return color
      },
      getTextColorForEvent (item) {
        let color = false

        if (item.details.hasOwnProperty('torneo')) {
          color = this.isDarkColor(this.rgbaColor(item.details.torneo.color)) ? 'white' : 'black'
        } else {
          color = this.isDarkColor(this.rgbaColor(item.details.color)) ? 'white' : 'black'
        }

        return color
      },
    },
  }
</script>

<style lang="sass">
  #calendar
    .v-calendar-weekly__day:last-child,
    .v-calendar-weekly__head-weekday:last-child
      border-right: none

    .v-calendar-weekly__week:last-child .v-calendar-weekly__day
      border-bottom: none
</style>
